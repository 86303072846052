import React from "react";
import PrismicRichText from "../components/PrismicRichText";

export default function TextSlice(slice) {
  const sectionStyle = {
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color,
  };

  const renderTextSections = (sections) => {
    return sections.map((el, i) => (
      <div key={i} className="text-content">
        <PrismicRichText render={el.rich_content} />
      </div>
    ));
  };

  return (
    <section className="simple-section text-slice" style={sectionStyle}>
      <div className="wrapper">{renderTextSections(slice.items)}</div>
    </section>
  );
}
