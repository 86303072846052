import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import PrismicRichText from "../components/PrismicRichText";
import renderButtons from "../helpers/renderButtons";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

export default function BoldImageSlice(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  return (
    <section className="section-half bold-image-slice">
      <div className="wrapper">
        <div className="text-content">
          <PrismicRichText render={get(slice, "primary.rich_content")} />
          <div className="button-container">
            {renderButtons(get(slice, "items"))}
          </div>
        </div>
        <div className="img-content">
          <LazyLoadImage
            src={
              isMobile
                ? get(slice, "primary.image_new.Mobile.url")
                : get(slice, "primary.image_new.url")
            }
            alt={
              isMobile
                ? get(slice, "primary.image_new.Mobile.alt")
                : get(slice, "primary.image_new.alt")
            }
            effect="opacity"
          />
        </div>
      </div>
    </section>
  );
}
