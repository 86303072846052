import React, {useState} from "react";
import PrismicRichText from "../components/PrismicRichText";
import {get} from "lodash";

export default function InfoDropDownSlice(slice) {
  const [toggleInfo, setToggleInfo] = useState(false);

  return (
    <section className="info-drop-down-section">
      <div
        className={
          toggleInfo ? "info-wrapper expanded" : "info-wrapper not-expanded"
        }
      >
        <button
          className={
            toggleInfo
              ? "left expanded toggle-button"
              : "center not-expanded toggle-button"
          }
          onClick={() => setToggleInfo(!toggleInfo)}
        >
          <PrismicRichText render={get(slice, "primary.title")} />
          {!toggleInfo && <div className="triangle-down"></div>}
        </button>
        {toggleInfo && (
          <div className="toggled-info">
            <PrismicRichText render={get(slice, "primary.copy")} />
            <div className="collapse-wrapper">
              <button onClick={() => setToggleInfo(false)} className="collapse">
                <div className="triangle-up"></div>
                COLLAPSE
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
