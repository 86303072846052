import Prismic from "prismic-javascript";
let apiEndpoint;
let accessToken;

if (
  window.location.host.includes("localhost") ||
  window.location.host.includes("shigh")
) {
  apiEndpoint = "https://high-supply-production.cdn.prismic.io/api/v2";
  accessToken =
    "MC5YODVmaVJJQUFDY0F0OHho.77-9FQch77-977-9Ge-_ve-_ve-_vWghAQY3NFrvv71777-977-9UkDvv70h77-9Fu-_ve-_ve-_vUHvv70";
} else {
  apiEndpoint = "https://high-supply-production.cdn.prismic.io/api/v2";
  accessToken =
    "MC5YODVmaVJJQUFDY0F0OHho.77-9FQch77-977-9Ge-_ve-_ve-_vWghAQY3NFrvv71777-977-9UkDvv70h77-9Fu-_ve-_ve-_vUHvv70";
}

// -- Prismic Client
const PrismicClient = Prismic.client(apiEndpoint, {accessToken});

export default PrismicClient;
