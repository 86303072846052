import React, {useState, useRef} from "react";
import FormInfo from "../components/FormInfo";
import Carousel from "../components/Carousel";

export default function ProductShowcaseSlice(slice) {
  const [currentForm, setCurrentForm] = useState(slice.items[0]);
  const [currentFormName, setCurrentFormName] = useState(
    slice.items[0].product_name[0].text
  );
  const myRef = useRef(null);

  const handleFormChange = (e) => {
    const found = slice.items.find(
      (el) => el.product_name[0].text === e.target.textContent
    );
    setCurrentForm(found);
    setCurrentFormName(e.target.textContent);
  };

  const renderButtons = (buttons) => {
    return buttons.map((button, i) => (
      <span
        onClick={handleFormChange}
        key={i}
        className={`option ${
          currentFormName === button.product_name[0].text && "active"
        }`}
      >
        {button.product_name[0].text}
      </span>
    ));
  };

  return (
    <section
      ref={myRef}
      className="products-page-section product-showcase-slice"
    >
      <div className="wrapper">
        <div className="carousel-container">
          <div className="options">{renderButtons(slice.items)}</div>
          <FormInfo form={currentForm}></FormInfo>
        </div>
        <Carousel form={currentForm}></Carousel>
      </div>
    </section>
  );
}
