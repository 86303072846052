import createSegmentScript from "./segment";

export function setupScripts() {
  const segmentId = "dDY8gz4oodxhygs6znIEFP06TMmEGAKs";
  if (window.analyticsInitialized) {
    return;
  }

  window.analyticsInitialized = true;
  setupSegment(segmentId);
}

function setupSegment(segmentKey) {
  var head = document.getElementsByTagName("head")[0];
  var segmentScript = document.createElement("script");
  var inlineScript = document.createTextNode(createSegmentScript(segmentKey));
  segmentScript.appendChild(inlineScript);
  head.appendChild(segmentScript);
}
