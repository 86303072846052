import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

export default function InfoBoxSlice(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));
  const backgroundImage = get(slice, "primary.background_image.url");

  const backgroundStyle = {
    backgroundImage: `url("${backgroundImage}")`,
  };

  const renderInfoBoxes = slice.items.map((el, i) => {
    return (
      <div className="box-wrapper" key={i}>
        <div className="box">
          <PrismicRichText render={get(el, "headline")} />
        </div>
        <div className="copy">
          <PrismicRichText render={get(el, "copy")} />
        </div>
      </div>
    );
  });

  return (
    <section className="info-box-slice" style={backgroundStyle}>
      <div className="wrapper">
        <div className="content">
          <PrismicRichText render={get(slice, "primary.copy")} />
        </div>
        <div className="info-boxes">{renderInfoBoxes}</div>
      </div>
    </section>
  );
}
