import React from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

export default function GenericDialog({ modalVisible, handleClose, children }) {
  return (
    <Dialog
      open={modalVisible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick="true"
      className="generic-modal"
      maxWidth={false}
      style={{
        backgroundColor: "rgba(0, 0, 0, .95);",
      }}
    >
      <IconButton
        onClick={handleClose}
        className="icon-close"
        edge="end"
        aria-label="dialog close"
      >
        <CloseIcon
          style={{
            fill: "white",
          }}
        />
      </IconButton>
      <div className="modalWrapper">
        <div>{children}</div>
      </div>
    </Dialog>
  );
}
