import React from "react";
import BoldImageSlice from "./BoldImageSlice";
import FullWidthSlice from "./FullWidthSlice";
import ProductTypesSlice from "./ProductTypesSlice";
import InstagramSlice from "./InstagramSlice";
import ProductShowcaseSlice from "./ProductShowcaseSlice";
import TextSlice from "./TextSlice";
import PDFSlice from "./PDFSlice";
import VideoListSlice from "./VideoListSlice";
import VideoThumbSlice from "./VideoThumbSlice";
import IFrameSlice from "./IFrameSlice";
import FAQSlice from "./FAQSlice";
import SliderHero from "./SliderHero";
import InfoBoxSlice from "./InfoBoxSlice";
import InfoDropDownSlice from "./InfoDropDownSlice";

export default function Slices(props) {
  if (!document) {
    return null;
  }

  return props.slices.map((slice, index) => (
    <div key={index}>
      {slice.slice_type === "bold_image_slice" && BoldImageSlice(slice)}
      {slice.slice_type === "product_types_slice" && ProductTypesSlice(slice)}
      {slice.slice_type === "full_width_slice" && FullWidthSlice(slice)}
      {slice.slice_type === "instagram_slice" && InstagramSlice(slice)}
      {slice.slice_type === "product_showcase_slice" &&
        ProductShowcaseSlice(slice)}
      {slice.slice_type === "text_slice" && TextSlice(slice)}
      {slice.slice_type === "pdf_slice" && PDFSlice(slice)}
      {slice.slice_type === "video_list_slice" && VideoListSlice(slice)}
      {slice.slice_type === "video_thumbs_slice" && VideoThumbSlice(slice)}
      {slice.slice_type === "iframe_slice" && IFrameSlice(slice)}
      {slice.slice_type === "faq_slice" && FAQSlice(slice)}
      {slice.slice_type === "slider_hero" && SliderHero(slice)}
      {slice.slice_type === "info_box_slice" && InfoBoxSlice(slice)}
      {slice.slice_type === "info_drop_down" && InfoDropDownSlice(slice)}
    </div>
  ));
}
