import React from "react";
import {useSelector, useDispatch} from "react-redux";
import {get} from "lodash";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export default function AccessibilitySwitch() {
  const dispatch = useDispatch();
  const handleChange = (event) => {
    dispatch({
      type: "ACCESSIBILITY/SAVE",
      show: event.target.checked,
    });
  };
  const showAccessibility = useSelector((state) =>
    get(state, "accessibility.show", false)
  );
  return (
    <FormControlLabel
      control={
        <Switch
          className="ada-switch mr-5"
          checked={showAccessibility}
          onChange={handleChange}
          name="accessibility-switch"
          inputProps={{"aria-label": "Toggle Accessibility"}}
          color="primary"
        />
      }
      label={showAccessibility ? "Accessibility On" : "Turn On Accessibility"}
    ></FormControlLabel>
  );
}
