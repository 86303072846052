import React, {useState, useEffect} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

const Carousel = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  useEffect(() => {
    setCurrentSlide(0);
  }, [props.form]);

  const slides = [
    isMobile
      ? get(props, "form.image_1.Mobile.url")
      : get(props, "form.image_1.url"),
    isMobile
      ? get(props, "form.image_2.Mobile.url")
      : get(props, "form.image_2.url"),
    isMobile
      ? get(props, "form.image_3.Mobile.url")
      : get(props, "form.image_3.url"),
  ].filter((el) => el);

  const altText = [
    isMobile
      ? get(props, "form.image_1.Mobile.alt")
      : get(props, "form.image_1.alt"),
    isMobile
      ? get(props, "form.image_2.Mobile.alt")
      : get(props, "form.image_2.alt"),
    isMobile
      ? get(props, "form.image_3.Mobile.alt")
      : get(props, "form.image_3.alt"),
  ].filter((el) => el);

  const previousSlide = () => {
    if (currentSlide - 1 !== -1) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextSlide = () => {
    if (currentSlide + 1 < slides.length) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const renderSlides = (slides) => {
    return slides.map((el, i) => (
      <div className={`product-image ${currentSlide === i && "active"} wh-100`}>
        <LazyLoadImage
          key={i}
          src={el}
          alt={altText[i] ? altText[i] : "product slide"}
          effect="opacity"
          className="wh-100"
          wrapperClassName="wh-100"
        />
      </div>
    ));
  };

  return (
    <div className="product-carousel">
      <div className="images">{renderSlides(slides)}</div>
      <div className="controls">
        <div onClick={previousSlide} className="back"></div>
        <div onClick={nextSlide} className="forward"></div>
      </div>
    </div>
  );
};

export default Carousel;
