import * as prismic from "@prismicio/client";

const endpoint = prismic.getRepositoryEndpoint("high-supply-production");
const accessToken =
  "MC5YODVmaVJJQUFDY0F0OHho.77-9FQch77-977-9Ge-_ve-_ve-_vWghAQY3NFrvv71777-977-9UkDvv70h77-9Fu-_ve-_ve-_vUHvv70"; // Set an access token
const routes = [];

const client = prismic.createClient(endpoint, {routes, accessToken});

export default client;
