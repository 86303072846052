import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {get, map} from "lodash";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Hero from "../components/Hero";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {Carousel} from "react-responsive-carousel";

let i = true;
const SliderHero = (slice) => {
  const dispatch = useDispatch();
  const ids = map(slice.items, "hero_slide.id");
  useEffect(() => {
    if (i) {
      dispatch({
        type: "PRISMIC_LOAD_LIST_BY_IDS",
        reduxKey: "hero_slides",
        ids,
      });
      i = false;
    }
  }, [dispatch, ids, i]);

  const heroSlidesLoading = useSelector((state) =>
    get(state, "prismic.hero_slides.loading", true)
  );

  const heroSlides = useSelector((state) =>
    get(state, "prismic.hero_slides.data", false)
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("700"));

  if (!heroSlides) {
    return null;
  }

  return heroSlides.length > 1 ? (
    <Carousel
      showStatus={false}
      autoPlay={true}
      infiniteLoop={true}
      stopOnHover={true}
    >
      {heroSlides.map((el, i) => (
        <Hero
          richContent={get(el, "data.hero_section[0].rich_content")}
          buttons={get(el, "data.buttons")}
          key={i}
          desktopBackgroundImage={get(
            el,
            "data.hero_section[0].desktop_image.url"
          )}
          mobileBackgroundImage={get(
            el,
            "data.hero_section[0].mobile_image.url"
          )}
        />
      ))}
    </Carousel>
  ) : (
    <Hero
      richContent={get(heroSlides, "[0]data.hero_section[0].rich_content")}
      buttons={get(heroSlides, "[0]data.buttons")}
      desktopBackgroundImage={get(
        heroSlides,
        "[0]data.hero_section[0].desktop_image.url"
      )}
      mobileBackgroundImage={get(
        heroSlides,
        "[0]data.hero_section[0].mobile_image.url"
      )}
    />
  );
};

export default SliderHero;
