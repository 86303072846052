import React, {useEffect} from "react";
import {RichText} from "prismic-reactjs";
import {useSelector, useDispatch} from "react-redux";
import Link from "../Link";
import Grid from "@material-ui/core/Grid";

import {get} from "lodash";

export default function VideoSlice({
  videoThumbnail,
  videoTitle,
  videoDescription,
  videoID,
  pagePath,
}) {
  const dispatch = useDispatch();

  const videosListLoading = useSelector((state) =>
    get(state, "prismic.videos_list.loading", true)
  );
  const videosList = useSelector((state) =>
    get(state, "prismic.videos_list.data", false)
  );

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_REPEATABLE",
      documentType: "video",
      reduxKey: "videos_list",
    });
  }, [dispatch]);

  const renderOtherVideos = () => {
    return videosList.map(
      (video, i) =>
        video.uid !== pagePath && (
          <Link key={i} to={`/videos/${video.uid}`}>
            <img
              src={video.data.thumbnail.url}
              alt={video.data.video_title[0].text + "thumbnail"}
            />
            <h3>{video.data.video_title[0].text}</h3>
          </Link>
        )
    );
  };

  return (
    <section className="video-single">
      <Grid container spacing={0}>
        <Grid item xs={12} md={9} className="video-and-description">
          <div className="video-embed-container">
            <iframe
              id="ytplayer"
              type="text/html"
              width="720"
              height="405"
              src={`https://www.youtube.com/embed/${videoID}`}
              frameborder="0"
              allowfullscreen
              title="Youtube Player"
            />
          </div>
          <div className="video-description">
            <h1>{videoTitle}</h1>
            <RichText render={videoDescription} />
          </div>
        </Grid>

        {!videosListLoading && videosList && (
          <Grid item xs={12} md={3} className="related-videos">
            <h2>Related Videos</h2>
            <div className="related-videos-container">
              {renderOtherVideos()}
            </div>
          </Grid>
        )}
      </Grid>
    </section>
  );
}
