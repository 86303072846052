import React, {useState, useEffect} from "react";
import Link from "./../Link";
import {useSelector} from "react-redux";
import {get} from "lodash";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

export default function Header(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const [navOpen, setNavOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const componentLoading = useSelector((state) =>
    get(state, "prismic.header.loading", true)
  );
  const component = useSelector((state) =>
    get(state, "prismic.header.data", false)
  );

  const logo = get(component, "logo.url", false);
  const navigationLinks = get(component, "navigation", false);

  const renderNavigation = (navigationLinks) => {
    return navigationLinks.map((el, i) => {
      return (
        <Link
          onClick={() => {
            setNavOpen(false);
          }}
          key={i}
          to={el.link_url.uid}
          url={el.link_url.url}
          target={el.link_url.target}
          className={`${
            props.activePage === `/${el.link_url.uid}` ? "active" : null
          }
            ${el.link_url.uid === "find-us" ? "hide" : null}
            
          `}
        >
          {el.link_text[0].text}
        </Link>
      );
    });
  };

  const renderShopButton = () => {
    return (
      <>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          variant="contained"
          color="primary"
          size="small"
          disableElevation
        >
          Shop
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Link to="/shop">Buy Online</Link>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Link to="/find-us">Store Locator</Link>
          </MenuItem>
        </Menu>
      </>
    );
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {}, [navOpen]);

  return (
    !componentLoading &&
    component && (
      <>
        <div className="header-spacer"></div>
        <header>
          <div className="wrapper">
            <Link to="/">
              <img id="logo" className="logo" src={logo} alt="Logo" />
            </Link>
            <div
              className={`nav-menu ${navOpen && "close"}`}
              onClick={toggleNav}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <nav
              id="navigation-container"
              className={(navOpen && "open") || null}
            >
              {renderNavigation(navigationLinks)}
              {isMobile ? (
                <>
                  <Link
                    onClick={() => {
                      setNavOpen(false);
                    }}
                    to={`/shop`}
                    className={`${
                      props.activePage === `/shop` ? "active" : null
                    }`}
                  >
                    Shop Now
                  </Link>

                  <Link
                    onClick={() => {
                      setNavOpen(false);
                    }}
                    to={`/find-us`}
                    className={`${
                      props.activePage === `/find-us` ? "active" : null
                    }`}
                  >
                    Store Locator
                  </Link>
                </>
              ) : (
                renderShopButton()
              )}
            </nav>
          </div>
        </header>
      </>
    )
  );
}
