import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import PrismicRichText from "../components/PrismicRichText";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import URLCheckerLink from "../components/Link/URLCheckerLink";

export default function InstagramSlice(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));
  const renderPhotos = (photos) => {
    return photos.map((photo, i) => (
      <URLCheckerLink
        href={`https://www.instagram.com/${slice.primary.instagram_handle[0].text}`}
        url={`https://www.instagram.com/${slice.primary.instagram_handle[0].text}`}
        key={i}
      >
        <LazyLoadImage
          src={
            isMobile
              ? get(photo, "image_new.Mobile.url")
              : get(photo, "image_new.url")
          }
          alt={
            isMobile
              ? get(photo, "image_new.Mobile.alt")
              : get(photo, "image_new.alt")
          }
          effect="opacity"
        />
      </URLCheckerLink>
    ));
  };

  return (
    <section className="instagram-section instagram-slice">
      <div className="wrapper">
        <div className="flex-wrapper">
          <div className="content">
            <PrismicRichText render={get(slice, "primary.rich_content")} />
          </div>
          <div className="buttons">
            <URLCheckerLink
              href={`https://www.instagram.com/${get(
                slice,
                "primary.instagram_handle[0].text"
              )}`}
              url={`https://www.instagram.com/${get(
                slice,
                "primary.instagram_handle[0].text"
              )}`}
              className="button solid"
              target="_blank"
            >
              @{get(slice, "primary.instagram_handle[0].text")}
            </URLCheckerLink>
          </div>
        </div>
        <div className="photos">{renderPhotos(get(slice, "items"))}</div>
      </div>
    </section>
  );
}
