import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import {Link} from "react-router-dom";
import isValidLink from "../helpers/isValidLink";

export default function FullWidthSlice(slice) {
  const componentStyle = {
    backgroundImage: "url(" + slice.primary.desktop_image.url + ")",
    backgroundColor: slice.primary.background_color,
    color: slice.primary.text_color,
  };

  const sectionAlignment =
    slice.primary.section_alignment === "Right" ? "align-right" : "align-left";

  return (
    <section
      className={`map-section full-width-slice ${sectionAlignment}`}
      style={componentStyle}
    >
      <div className="wrapper">
        <div className="text-content">
          <PrismicRichText render={slice.primary.rich_content} />
        </div>
        <div className="buttons">
          {isValidLink(slice.primary.cta_link, slice.primary.cta_text) && (
            <Link to={slice.primary.cta_link.uid} className="button solid">
              {slice.primary.cta_text[0].text}
            </Link>
          )}
        </div>
      </div>
    </section>
  );
}
