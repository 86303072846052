import React from "react";
import {useSelector} from "react-redux";
import {get, map} from "lodash";
import AccessibilitySwitch from "../AccessibilitySwitch";
import CookieSettingsLink from "./CookieSettingsLink";
import Link from "../Link";
import PrismicRichText from "../PrismicRichText";

export default function Footer() {
  const componentLoading = useSelector((state) =>
    get(state, "prismic.footer.loading", true)
  );
  const component = useSelector((state) =>
    get(state, "prismic.footer.data", false)
  );

  const backgroundColor = get(component, "background_color");
  const backgroundImage = get(component, "background_image.url");
  const textColor = get(component, "text_color");
  const copyright = get(component, "copyright");

  const backgroundStyle = {
    color: textColor,
    backgroundColor: backgroundColor,
    backgroundImage: `url("${backgroundImage}")`,
  };

  const renderFooterColumns = (columns) => {
    return columns.map((el, i) => (
      <div key={i} className="footer-section">
        <div className="heading">
          <PrismicRichText render={el?.column_title} />
        </div>
        <div className="content">
          <PrismicRichText render={el?.content} />
        </div>
      </div>
    ));
  };

  const renderSocialLinks = (socialLinks) => {
    return map(socialLinks, (el, i) => (
      <Link
        key={i}
        href={get(el, "link.url")}
        url={get(el, "link.url")}
        target="_blank"
      >
        <img src={get(el, "icon.url")} alt="Social Icon" />
      </Link>
    ));
  };

  return (
    !componentLoading &&
    component && (
      <footer className="standard-padding" style={backgroundStyle}>
        <div className="wrapper">
          <div className="footer-section">
            <Link
              className="tagline"
              href="https://www.crescolabs.com"
              url="https://www.crescolabs.com"
              target="_blank"
            >
              <span className="mr-10">Grown by </span>
              <span className="logo-text">CRESCO LABS</span>
            </Link>
            <p>
              We’re on a mission to normalize, professionalize, and
              revolutionize cannabis.
            </p>
          </div>
          {renderFooterColumns(component?.footer)}
          <div className="footer-section">
            <div class="cookie-settings mb-10">
              <CookieSettingsLink />
            </div>
            <div className="social">
              <div className="copyright">
                <PrismicRichText render={copyright} />
              </div>
              <div className="accessibility mb-20">
                <AccessibilitySwitch />
              </div>
              {renderSocialLinks(component?.social_links)}
            </div>
          </div>
        </div>
      </footer>
    )
  );
}
