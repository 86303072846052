import React, {useState} from "react";
import PrismicRichText from "../PrismicRichText";
import {useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import renderButtons from "../../helpers/renderButtons";

export default function Hero(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("700"));
  const [specialText, setSpecialText] = useState(false);

  const backgroundStyle = {
    backgroundImage: isMobile
      ? "url(" + props.mobileBackgroundImage + ")"
      : "url(" + props.desktopBackgroundImage + ")",
    position: !isMobile && props.textBlockBackground ? "static" : "absolute",
    height: !isMobile && props.textBlockBackground ? "auto" : "100%",
    order:
      !isMobile && props.sectionAlignment === "Image / Content" ? "-100" : "1",
  };
  const textBlockStyle = {
    background: props.backgroundColor,
  };

  const wrapperStyle = {
    margin: "0px",
    display: "flex",
    maxWidth: props.textBlockBackground ? "none" : "1200px",
  };

  const replaceText = (ref) => {
    if (ref && !specialText) {
      const textToReplace = ref.innerHTML;
      if (
        textToReplace.includes("High Supply") &&
        textToReplace.includes("VICE")
      ) {
        const newText = textToReplace
          .replace(
            "High Supply",
            "<img class='logo-text high-supply-logo-text' src='https://images.prismic.io/high-supply-staging/f3d60362-268b-4c5e-8d90-8dbe82af4d9e_hs-logo-black.png?auto=compress,format'/>"
          )
          .replace(
            "VICE",
            "<img class='logo-text vice-logo-text' src='https://images.prismic.io/high-supply-staging/ecd0f015-6d0b-49d0-9abb-f588e382327f_vice-logo-black.png?auto=compress,format'/>"
          );
        setSpecialText(newText);
      }
    }
  };

  return (
    <section
      className="hero-section"
      style={!props.textBlockBackground ? textBlockStyle : null}
    >
      <div
        className="wrapper"
        style={!isMobile && props.textBlockBackground ? wrapperStyle : null}
      >
        <div
          className="text-content"
          style={!isMobile && props.textBlockBackground ? textBlockStyle : null}
          ref={replaceText}
        >
          {specialText ? (
            <div dangerouslySetInnerHTML={{__html: specialText}} />
          ) : props.headline ? (
            <h1>{props.headline}</h1>
          ) : (
            <PrismicRichText render={props.richContent} />
          )}

          {props.buttons && (
            <div className={`buttons`}>{renderButtons(props.buttons)}</div>
          )}
        </div>
        <div className="background" style={backgroundStyle}></div>
        <div className="overlay"></div>
      </div>
    </section>
  );
}
