import React from "react";
import ShopPageDialog from "../components/Dialogs/ShopPageDialog";

export default function PrismicPage(props) {
  return (
    <div>
      <div className="simple-page" style={{marginTop: "64px"}}>
        <div className="wrapper">
          <ShopPageDialog />
        </div>
      </div>
    </div>
  );
}
