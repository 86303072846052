import React, {useEffect, useState} from "react";
import PrismicRichText from "../components/PrismicRichText/index.js";
import {useSelector, useDispatch} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import {get} from "lodash";
import viceLogo from "../images/high-supply-vice-logo.png";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import GenericDialog from "../components/Dialogs/genericModal.js";

export default function VideoListSlice(slice) {
  const dispatch = useDispatch();
  const [videosList, setVideosList] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All Videos");
  const [categoriesList, setCategoriesList] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [videoID, setVideoID] = useState(false);

  const videosLoading = useSelector((state) =>
    get(state, "prismic.videos_list.loading", true)
  );
  const videos = useSelector((state) =>
    get(state, "prismic.videos_list.data", false)
  );

  const howWeRollCopy = get(slice, "primary.how_we_roll_copy");
  const iykykCopy = get(slice, "primary.iykyk_copy");

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_REPEATABLE",
      documentType: "video",
      reduxKey: "videos_list",
    });
  }, [dispatch]);

  useEffect(() => {
    if (videos && !videosLoading) {
      setVideosList(videos);
      setCategoriesList(removeDuplicates(videos));
    }
  }, [videos, videosLoading]);

  useEffect(() => {
    if (videos && selectedCategory !== "All Videos") {
      setVideosList(
        videos.filter((el) => el.data.video_category === selectedCategory)
      );
    } else {
      setVideosList(videos);
    }
  }, [selectedCategory]);

  const removeDuplicates = (array) => {
    const categories = [];
    array.map((category, i) => {
      categories.push(category.data.video_category);
    });
    let unique = [...new Set(categories)];
    return unique;
  };

  const categoryCopy = () => {
    if (selectedCategory === "How We Roll") {
      return <PrismicRichText render={howWeRollCopy} />;
    }
    if (selectedCategory === "IYKYK") {
      return <PrismicRichText render={iykykCopy} />;
    }
  };

  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleVideoClick = (videoID) => {
    setModalVisible(true);
    setVideoID(videoID);
  };

  const handleClose = () => {
    setModalVisible(false);
  };

  const renderVideoThumbails = () => {
    return videosList.map((video, i) => (
      <div className="video-list-item" key={i}>
        <button
          className="playVideo"
          onClick={() => {
            handleVideoClick(video.data.youtube_video_id[0].text);
          }}
        >
          <div>
            <Grid item className="image-content">
              <img
                src={video.data.thumbnail.url}
                alt={video.data.video_title[0].text + "thumbnail"}
              />
            </Grid>
            <div className="text-content smaller-text">
              {/* {video.data.video_category !== "IYKYK" && (
                <img className="vice-logo" src={viceLogo} alt="Vice Logo" />
              )} */}
              <h2>{video.data.video_title[0].text}</h2>
              <PrismicRichText render={video.data.video_description} />
            </div>
          </div>
        </button>
      </div>
    ));
  };

  return (
    <section className="video-list-slice less-standard-padding">
      {!videosLoading && videos && videosList && (
        <div>
          <GenericDialog modalVisible={modalVisible} handleClose={handleClose}>
            <iframe
              id="ytplayer"
              className="youtubeIframe"
              type="text/html"
              width="820px"
              height="520px"
              src={`https://www.youtube.com/embed/${videoID}`}
              frameborder="0"
            />
          </GenericDialog>
          <div className="filters">
            <div className="filters-wrapper">
              <h2 className="sort">SORT:</h2>
              <FormControl>
                <TextField
                  id="select-strain-type"
                  select
                  name="select-strain"
                  value={selectedCategory}
                  onChange={handleChange}
                  style={{marginBottom: "20px"}}
                  variant="outlined"
                  color="primary"
                >
                  <MenuItem value="All Videos">All Videos</MenuItem>
                  {categoriesList &&
                    categoriesList.map((category, i) => (
                      <MenuItem key={i} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                </TextField>
              </FormControl>
            </div>
            <p className="filter-copy">{categoryCopy()}</p>
          </div>
          <div className="video-grid">{renderVideoThumbails()}</div>
        </div>
      )}
    </section>
  );
}
