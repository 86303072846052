import React, {useEffect, useState} from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {get} from "lodash";
import {RichText} from "prismic-reactjs";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

export default function VideoThumbSlice(slice) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  const [specialText, setSpecialText] = useState(false);

  const videosLoading = useSelector((state) =>
    get(state, "prismic.videos_list.loading", true)
  );
  const videos = useSelector((state) =>
    get(state, "prismic.videos_list.data", false)
  );

  const richContent = get(slice, "primary.rich_content");
  const backgroundColor =
    get(slice, "primary.background_color") === "Black" ? "black_back" : "";

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_REPEATABLE",
      documentType: "video",
      reduxKey: "videos_list",
      max: 3,
    });
  }, [dispatch]);

  const renderThumbnails = () => {
    return videos.map((video, i) => (
      <Link to={`/videos/${video.uid}`} key={i}>
        <LazyLoadImage
          src={
            isMobile
              ? get(video, "data.image_new.Mobile.url")
              : get(video, "data.image_new.url")
          }
          alt={
            isMobile
              ? get(video, "data.image_new.Mobile.alt")
              : get(video, "data.image_new.alt")
          }
          effect="opacity"
        />
      </Link>
    ));
  };

  if (videosLoading && !videos) {
    return null;
  }

  const replaceText = (ref) => {
    if (ref && !specialText) {
      const textToReplace = ref.innerHTML;
      if (
        textToReplace.includes("High Supply") &&
        textToReplace.includes("VICE")
      ) {
        const newText = textToReplace
          .replace(
            "High Supply",
            "<img class='logo-text high-supply-logo-text' src='https://images.prismic.io/high-supply-staging/f3d60362-268b-4c5e-8d90-8dbe82af4d9e_hs-logo-black.png?auto=compress,format'/>"
          )
          .replace(
            "VICE",
            "<img class='logo-text vice-logo-text' src='https://images.prismic.io/high-supply-staging/ecd0f015-6d0b-49d0-9abb-f588e382327f_vice-logo-black.png?auto=compress,format'/>"
          );
        setSpecialText(newText);
      }
    }
  };

  return (
    !videosLoading &&
    videos && (
      <section
        className={`instagram-section instagram-slice video-thumb-slice ${backgroundColor}`}
      >
        <div className="wrapper">
          <div className="flex-wrapper">
            <div className="content" ref={replaceText}>
              {specialText ? (
                <div dangerouslySetInnerHTML={{__html: specialText}} />
              ) : (
                <RichText render={richContent} />
              )}
            </div>

            <div className="buttons">
              <Link to="/videos" className="button solid">
                View All Videos
              </Link>
            </div>
          </div>

          <div className="photos">{renderThumbnails()}</div>
        </div>
      </section>
    )
  );
}
