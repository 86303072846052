import React, {useState} from "react";
import logo from "../../images/logos/logo-stacked.svg";
import Link from "../Link";
import Cookie from "js-cookie";
import {Button} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";

const StlSwitch = styled(Switch)`
  .MuiSwitch-track {
    background: white;
  }
`;

export default function AgeGate(props) {
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [spanish, toggleSpanish] = useState(false);

  const handleCheckbox = (e) => {
    setCheckboxChecked(e.target.checked);
  };

  const handleSwitch = () => {
    toggleSpanish((prev) => !prev);
  };

  const copy = {
    english: {
      headline: "Are You Over 21?",
      body: (
        <>
          Yes, I am at least twenty-one (21) years of age or am otherwise a
          qualified patient. By clicking “I agree” and entering the website, I
          agree to be bound by the{" "}
          <Link to="/terms-of-service">Terms of Service</Link> and{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>.
        </>
      ),
      checkbox: "I Agree",
      button: "Enter Site",
    },
    spanish: {
      headline: "¿Es mayor de 21 años?",
      body: (
        <>
          Sí, tengo al menos veintiún (21) años o soy un paciente calificado. Al
          hacer clic en “Acepto” y entrar en el sitio web, acepto los{" "}
          <Link to="/terms-of-service">Términos de servicio</Link> y la{" "}
          <Link to="/privacy-policy">Política de privacidad</Link>.
        </>
      ),
      checkbox: "Acepto",
      button: "Ingresar al sitio",
    },
  };

  const localizedCopy = spanish ? copy.spanish : copy.english;

  const allowPassage = (event) => {
    event.preventDefault();
    if (checkboxChecked) {
      Cookie.set("hs_over21", true, {expires: 3});
      setVerified(true);
      props.verifyAge();
    } else {
      setError(true);
    }
  };

  return (
    !verified && (
      <div
        id="age-gate"
        className={(error && !checkboxChecked && "error") || ""}
      >
        <div className="age-gate-content right">
          <img className="age-gate-logo" alt="logo" src={logo} />

          <div className="spanish-toggle mt-15">
            <FormControlLabel
              control={
                <StlSwitch
                  checked={spanish}
                  onChange={handleSwitch}
                  color="secondary"
                  className="mr-5"
                />
              }
              label={!spanish ? "ES" : "EN"}
            />
          </div>

          <h2>{localizedCopy.headline}</h2>
          <p>{localizedCopy.body}</p>
          <form id="age-gate-form" onSubmit={allowPassage}>
            <FormControlLabel
              value="start"
              className="mb-20"
              variant="outlined"
              control={
                <Checkbox
                  checked={checkboxChecked}
                  onChange={handleCheckbox}
                  color="secondary"
                  inputProps={{"aria-label": localizedCopy.checkbox}}
                />
              }
              label={localizedCopy.checkbox}
              labelPlacement="end"
            />

            {error && !checkboxChecked && (
              <p className="error-message">
                Please confirm you are over 21 years of age.
              </p>
            )}
            <br></br>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disableElevation
              style={{
                marginTop: "10px",
                color: "white",
                fontFamily: "Averta Bold",
                background: "#8a8c8f",
              }}
            >
              {localizedCopy.button}
            </Button>
          </form>
        </div>
      </div>
    )
  );
}
