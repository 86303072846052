import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { get } from "lodash";
import Slices from "../slices";
import { Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import VideoSingle from "../components/VideoSingle";

export default function PrismicPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const loadingFunc = props.loading;
  const animation = props.animation;

  const pagePath = location.pathname.split("/")[2];

  const videoLoading = useSelector((state) =>
    get(state, "prismic.video.loading", true)
  );
  const video = useSelector((state) => get(state, "prismic.video.data", false));

  const videoTitle = get(video, "video_title[0].text", false);
  const videoThumbnail = get(video, "thumbnail.url", false);
  const videoDescription = get(video, "video_description", false);
  const videoID = get(video, "youtube_video_id[0].text", false);

  const globalSlicesLoading = useSelector((state) =>
    get(state, "prismic.global_slices.loading", true)
  );
  const globalSlices = useSelector((state) =>
    get(state, "prismic.global_slices.data.body", false)
  );

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_BY_UID",
      key: "video",
      id: pagePath,
    });
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_PAGE",
      key: "global_slices",
    });
  }, [dispatch, pagePath]);

  useEffect(() => {
    if (!videoLoading) {
      loadingFunc("yas");
    }
  }, [pagePath, videoLoading, loadingFunc]);

  if (videoLoading) {
    return (
      <CircularProgress
        style={{
          position: "fixed",
          color: "black",
          top: "calc(50% - 25px)",
          left: "calc(50% - 25px)",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  if (!videoLoading && !video) {
    return <Redirect to={"/404"} />;
  }
  return (
    !videoLoading &&
    video && (
      <div className={`animation ${animation}`}>
        <VideoSingle
          videoThumbnail={videoThumbnail}
          videoTitle={videoTitle}
          videoDescription={videoDescription}
          videoID={videoID}
          pagePath={pagePath}
        />

        {!globalSlicesLoading && globalSlices && (
          <Slices slices={globalSlices}></Slices>
        )}
      </div>
    )
  );
}
