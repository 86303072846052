import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {get} from "lodash";
import Hero from "../components/Hero";
import Slices from "../slices";
import {Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function PrismicPage(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const loadingFunc = props.loading;
  const animation = props.animation;

  const whichPage = (path) => {
    if (path === "/") {
      return "home";
    } else if (path === "/404") {
      return "404";
    } else if (path.includes("/videos/")) {
      return "videos";
    } else {
      return path.substr(1);
    }
  };

  const pagePath = whichPage(location.pathname);

  const pageLoading = useSelector((state) =>
    get(state, "prismic.page.loading", true)
  );
  const page = useSelector((state) => get(state, "prismic.page.data", false));

  const globalSlicesLoading = useSelector((state) =>
    get(state, "prismic.global_slices.loading", true)
  );
  const globalSlices = useSelector((state) =>
    get(state, "prismic.global_slices.data.body", false)
  );

  //hero section data
  const richContent = get(page, "hero_section[0].rich_content");
  const sectionAlignment = get(page, "hero_section[0].section_alignment");
  const backgroundColor = get(page, "hero_section[0].background_color");
  const mobileBackgroundImage = get(page, "hero_section[0].mobile_image.url");
  const desktopBackgroundImage = get(page, "hero_section[0].desktop_image.url");
  const textBlockBackground = get(
    page,
    "hero_section[0].text_block_background"
  );
  //ctas
  const buttons = get(page, "buttons");
  //page slices
  const slices = get(page, "body");
  //show global slices?
  const showGlobalSlices = get(page, "global_slices");

  const showHero = get(page, "show_hero");

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_BY_UID",
      key: "page",
      id: pagePath,
    });
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_PAGE",
      key: "global_slices",
    });
  }, [dispatch, pagePath]);

  useEffect(() => {
    if (!pageLoading) {
      loadingFunc("yas");
    }
  }, [pagePath, pageLoading, loadingFunc]);

  if (pageLoading) {
    return (
      <CircularProgress
        style={{
          position: "fixed",
          color: "black",
          top: "calc(50% - 25px)",
          left: "calc(50% - 25px)",
          width: "50px",
          height: "50px",
        }}
      />
    );
  }

  if (!pageLoading && !page) {
    return <Redirect to={"/404"} />;
  }
  return (
    !pageLoading &&
    page && (
      <div className={`animation ${animation}`}>
        {showHero && (
          <Hero
            richContent={richContent}
            backgroundColor={backgroundColor}
            buttons={buttons}
            desktopBackgroundImage={desktopBackgroundImage}
            mobileBackgroundImage={mobileBackgroundImage}
            textBlockBackground={textBlockBackground}
            sectionAlignment={sectionAlignment}
          />
        )}

        <Slices slices={slices}></Slices>
        {showGlobalSlices && !globalSlicesLoading && globalSlices && (
          <Slices slices={globalSlices}></Slices>
        )}
      </div>
    )
  );
}
