import React from "react";
import {get} from "lodash";
import PrismicRichText from "../components/PrismicRichText";

export default function IFrameSlice(slice) {
  const id = get(slice, "primary.id[0].text");
  const copy = get(slice, "primary.copy");
  const src = get(slice, "primary.iframe_url.url");

  return (
    <div id={id} className="iframe-slice">
      {copy && (
        <div className="copy-wrapper">
          <PrismicRichText render={copy} />
        </div>
      )}
      <div className="wrapper">
        <iframe
          title="Embedded Content"
          name="iFrame Embed"
          frameBorder="0"
          src={src}
        />
      </div>
    </div>
  );
}
