import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import PrismicRichText from "../components/PrismicRichText";
import {Link} from "react-router-dom";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

export default function ProductTypesSlice(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));
  const renderProductIcons = (products) => {
    return products.map((product, i) => (
      <div className="product-icon" key={i}>
        <LazyLoadImage
          src={
            isMobile
              ? get(product, "image_new.Mobile.url")
              : get(product, "image_new.url")
          }
          alt={
            isMobile
              ? get(product, "image_new.Mobile.alt")
              : get(product, "image_new.alt")
          }
          effect="opacity"
        />
        <PrismicRichText render={product.rich_content} />
      </div>
    ));
  };

  return (
    <section className="products product-types-slice">
      <div className="wrapper">
        <div className="section-description">
          <PrismicRichText render={get(slice, "primary.rich_content")} />
        </div>
        <div className="product-icons">
          {renderProductIcons(get(slice, "items"))}
        </div>
        <Link className="button" to="/products">
          View All Products
        </Link>
      </div>
    </section>
  );
}
