import React from "react";
import NewPrismicPage from "./pages/NewPrismicPage";
import ShopPage from "./pages/ShopPage";
import VideoDetailsPage from "./pages/VideoDetailsPage";
import StoreLocatorPage from "./pages/StoreLocatorPage";
import {Switch, Route, Redirect, useLocation} from "react-router-dom";

export default function Routes({loading, animation, isStaging}) {
  const {pathname} = useLocation();
  const prismicPage = () => {
    return <NewPrismicPage loading={loading} animation={animation} />;
  };
  const videoDetailPage = () => {
    return <VideoDetailsPage loading={loading} animation={animation} />;
  };

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path="/shop" component={ShopPage} />
      <Route path="/find-us">
        <Redirect to="/store-locator" />
      </Route>
      <Route path="/videos/:id" render={videoDetailPage} />
      <Route exact path="/contact">
        <Redirect to="/404" />
      </Route>
      <Route exact path="/store-locator" component={StoreLocatorPage} />
      <Route path="/:id" render={prismicPage} />
      <Route exact path="/" render={prismicPage} />
    </Switch>
  );
}
