import React from "react";
import {LazyLoadImage} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import {RichText} from "prismic-reactjs";
import {get} from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";

export default function FormInfo(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("768"));

  return (
    <div className="form-info-container">
      <LazyLoadImage
        className="selected-form"
        src={
          isMobile
            ? get(props, "form.product_hero_new.Mobile.url")
            : get(props, "form.product_hero_new.url")
        }
        alt={
          isMobile
            ? get(props, "form.product_hero_new.Mobile.alt")
            : get(props, "form.product_hero_new.alt")
        }
        effect="opacity"
      />
      <div className="text-content">
        <h2>{get(props, "form.product_name[0].text")}</h2>
        <RichText render={get(props, "form.product_description")} />
      </div>
    </div>
  );
}
