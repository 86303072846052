import {createTheme} from "@material-ui/core/styles";
const themeObj = {
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#979797",
    },
  },
  typography: {
    fontFamily: '"neue-haas-unica", sans-serif',
  },

  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: '"neue-haas-unica", sans-serif',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "white",
      },
    },
    MuiSwitch: {
      root: {
        padding: "0",
        borderRadius: "18px",
        width: "53px",
        height: "24px",
      },
      thumb: {
        width: "18px",
        height: "18px",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
      },
      colorSecondary: {
        "&&&&$checked + $track": {
          opacity: "1",
          backgroundColor: "white",
        },
        "&&&&$checked": {
          transform: "translateX(calc(100% + 5px))",
          color: "black",
        },
      },
      switchBase: {
        padding: "3px",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&&&&$checked": {
          transform: "translateX(calc(100% + 5px))",
          color: "white",
        },
        "&&&&$checked + $track": {
          opacity: "1",
          backgroundColor: "black",
        },
        "&&&&$checked + &&&&$disabled + $track": {
          opacity: ".2",
        },
      },
    },
    MuIButton: {
      root: {
        "&:hover": {
          color: "white !important",
        },
      },
    },
    MuiFormLabel: {
      root: {
        marginBottom: "20px",
        color: "#000000",
      },
    },
    MuiInputLabel: {
      root: {
        color: "#979797",
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: ".8rem",
      },
    },
    MuiButton: {
      root: {
        fontFamily: '"neue-haas-unica", sans-serif !important',
        fontWeight: "900",
        borderRadius: "0",
        border: "2px solid black",
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: "white",
          color: "black",
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: '"neue-haas-unica", sans-serif !important',
        fontWeight: "900",
        textTransform: "uppercase",
        "& a": {
          textDecoration: "none",
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "0",
      },
    },
    MuiInputBase: {
      root: {
        minWidth: "200px",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: "2px solid",
      },
      root: {
        // borderRadius: "0",
        // minWidth: "200px",
      },
    },
    MuiSelect: {
      root: {
        // borderRadius: "0",
        // border: "5px solid black",
        // display: "inline-block",
        // textTransform: "uppercase",
        // fontWeight: "bold",
      },
      // icon: {
      //   top: "12px",
      //   fill: "black",
      //   width: "1.8em",
      //   height: "1.8em",
      // },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, .8)",
      },
    },
  },
};
const theme = createTheme(themeObj);
export {theme, themeObj};
